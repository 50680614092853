frappe.ready(() => {
	setup_file_size();
	pin_header();

	$("#allow_booking").addClass("disabled");

	// Disable the href attribute
	$("#allow_booking").attr("href", "javascript:void(0)");

	// Add additional CSS properties if needed
	$("#allow_booking").css({
		"pointer-events": "none",
		"cursor": "not-allowed",
		"opacity": "0.6"
	});
	$(".enroll-in-course").click((e) => {
		enroll_in_course(e);
	});

	$(".book_mentor").click((e) => {
		book_mentor(e);
	});

	$(".notify-me").click((e) => {
		notify_user(e);
	});

	$(".nav-link").click((e) => {
		change_hash(e);
	});

	if (window.location.hash) {
		open_tab();
	}

	if (window.location.pathname == "/statistics") {
		generate_graph("New Signups", "#new-signups");
		generate_graph("Course Enrollments", "#course-enrollments");
		generate_graph("Lesson Completion", "#lesson-completion");
		generate_course_completion_graph();
	}

	expand_the_active_chapter();

	$(".chapter-title")
		.unbind()
		.click((e) => {
			rotate_chapter_icon(e);
		});

	$(".no-preview").click((e) => {
		show_no_preview_dialog(e);
	});

	$("#create-batch").click((e) => {
		open_batch_dialog(e);
	});

	$("#course-filter").change((e) => {
		filter_courses(e);
	});
});

const pin_header = () => {
	const el = document.querySelector(".sticky");
	if (el) {
		const observer = new IntersectionObserver(
			([e]) =>
				e.target.classList.toggle("is-pinned", e.intersectionRatio < 1),
			{ threshold: [1] }
		);
		observer.observe(el);
	}
};

const setup_file_size = () => {
	frappe.provide("frappe.form.formatters");
	frappe.form.formatters.FileSize = file_size;
};

const file_size = (value) => {
	if (value > 1048576) {
		value = flt(flt(value) / 1048576, 1) + "M";
	} else if (value > 1024) {
		value = flt(flt(value) / 1024, 1) + "K";
	}
	return value;
};

const enroll_in_course = (e) => {
	e.preventDefault();
	let course = $(e.currentTarget).attr("data-course");
	if (frappe.session.user == "Guest") {
		window.location.href = `/login?redirect-to=/courses/${course}`;
		return;
	}

	let batch = $(e.currentTarget).attr("data-batch");
	batch = batch ? decodeURIComponent(batch) : "";
	frappe.call({
		method: "lms.lms.doctype.lms_enrollment.lms_enrollment.create_membership",
		args: {
			batch: batch ? batch : "",
			course: course,
		},
		callback: (data) => {
			if (data.message == "OK") {
				$(".no-preview-modal").modal("hide");
				frappe.show_alert(
					{
						message: __("Enrolled successfully"),
						indicator: "green",
					},
					3
				);
				setTimeout(function () {
					window.location.href = `/courses/${course}/learn/1.1`;
				}, 1000);
			}
		},
	});
};

const book_mentor = (e) => {
	e.preventDefault();
	let course = $(e.currentTarget).attr("data-course");
	if (frappe.session.user == "Guest") {
		window.location.href = `/login?redirect-to=/mentors/${course}`;
		return;
	}
	open_appointment_dialog(e)
};

const open_appointment_dialog = (e) => {
	let selected_slot = null;
	let service_unit = null;
	let duration = null;
	let add_video_conferencing = null;
	let overlap_appointments = null;
	let appointment_based_on_check_in = false;
	let course = $(e.currentTarget).attr("data-course");
	let appointment_fee = $(e.currentTarget).attr("data-total_appointment_fee");
	let totalAppointmentFee = $('.course-price').data('total_appointment_fee');
	let showPayment = !!totalAppointmentFee

	const frm = {
		doc: {
			appointment_date: ""
		},
		set_value(field, value) {
			this.doc[field] = value;
		},
		get_value(field) {
			return this.doc[field];
		},
		enable_save() {
			// Your custom save logic
		},
		save() {
			// Your custom save logic
		}
	};
	show_availability();

	function show_empty_state(practitioner, appointment_date) {
		frappe.msgprint({
			title: __('Not Available'),
			message: __('Mentor Not Available'),
			indicator: 'red'
		});
	}

	function createAppointment(data) {
		frappe.call({
			method: "mentorly_custom_app.mentorly_custom_app.custom_api.create_appointment",
			args: data,
			callback: function (response) {
				if (response.message && response.message.success == true) {
					$(".close-alt").hide()
					const appointment_id = response.message.appointment.name
					data.appointment_id = appointment_id
					var dialog = new frappe.ui.Dialog({
						title: 'We have reserved the slot!',
						fields: [
							{ fieldtype: 'HTML', fieldname: 'confirm_slot' },
						],
						content: '<p>Lets make payment and confirm booking</p>',
						isCancellable: true,
						primary_action_label: 'Proceed',
						primary_action: function () {
							if (showPayment) {
								frappe.require("controls.bundle.js", () => {
									setup_billing(data);
								});
								frappe.show_alert({
									message: __("Payment Successful"),
									indicator: "green",
								});
							} else {
								setTimeout(() => {
									window.location.pathname = "/my-appointments";
									// window.location.href = data.message;
								}, 1000);
							}
							dialog.hide();
						},
						// You can handle the dialog closing using the `onhide` event
						onhide: function () {
							console.log('Dialog closed');
						}
					});

					let $wrapper = dialog.fields_dict.confirm_slot.$wrapper;

					// make buttons for each slot
					let slot_html = `<div style="text-align: center;">
            <img src="https://png.pngtree.com/png-vector/20190228/ourmid/pngtree-check-mark-icon-design-template-vector-isolated-png-image_711429.jpg" style="max-width: 50%;">
            <p>Lets make payment and confirm booking</p>
        </div>`;

					$wrapper
						.css('margin-bottom', 0)
						.addClass('text-center')
						.html(slot_html);

					dialog.show();
					$(".close-alt").hide()

				} else {
					frappe.msgprint({
						title: __('Notification'),
						indicator: 'red',
						message: __('Appointment Failed, Please Try in sometime')
					});
				}
			}
		});
	}

	var billing_details = null;

	const setup_billing = (data) => {
		var current_user = frappe.session.user;
		frappe.call({
			method: 'mentorly_custom_app.mentorly_custom_app.custom_api.get_billing_address_by_email',
			args: {
				current_user
			},
			callback: function (response) {
				if (response.message) {
					console.log(response.message);  // Do something with the address data
					const address = response.message
					this.billing = new frappe.ui.Dialog({
						title: "",
						fields: [
							{
								fieldtype: "Data",
								label: __("Billing Name"),
								fieldname: "billing_name",
								reqd: 1,
								// default: address && address.billing_name,
							},
							{
								fieldtype: "Data",
								label: __("Address Line 1"),
								fieldname: "address_line1",
								reqd: 1,
								// default: address && address.address_line1,
							},
							{
								fieldtype: "Data",
								label: __("Address Line 2"),
								fieldname: "address_line2",
								// default: address && address.address_line2,
							},
							{
								fieldtype: "Data",
								label: __("City/Town"),
								fieldname: "city",
								reqd: 1,
								// default: address && address.city,
							},
							{
								fieldtype: "Column Break",
							},
							{
								fieldtype: "Data",
								label: __("State/Province"),
								fieldname: "state",
								// default: address && address.state,
							},
							{
								fieldtype: "Link",
								label: __("Country"),
								fieldname: "country",
								options: "Country",
								reqd: 1,
								only_select: 1,
								default: "India",
								change: () => {
									change_currency();
								},
							},
							{
								fieldtype: "Data",
								label: __("Postal Code"),
								fieldname: "pincode",
								reqd: 1,
								// default: address && address.pincode,
							},
							{
								fieldtype: "Data",
								label: __("Phone Number"),
								fieldname: "phone",
								reqd: 1,
								// default: address && address.phone,
							},
							{
								fieldtype: "Section Break",
								label: __("GST Details"),
								fieldname: "gst_details",
								depends_on: "eval:doc.country === 'India'",
							},
							{
								fieldtype: "Data",
								label: __("GSTIN"),
								fieldname: "gstin",
							},
							{
								fieldtype: "Column Break",
								fieldname: "gst_details_break",
							},
							{
								fieldtype: "Data",
								fieldname: "pan",
								label: __("PAN"),
							},
						],
						primary_action: function (e) {

							generate_payment_link(data)
							this.billing.hide();
						},
					});
					this.billing.onhide = null;
					this.billing.$wrapper.find('.close-alt').hide();
					$(".close-alt").hide()
					this.billing.$wrapper.on('click', '.modal', function (e) {
						e.stopPropagation();
					});
					$(document).on('click', '.modal', function (e) {
						e.stopPropagation();
					});

					const generate_payment_link = (data) => {
						let new_address = this.billing.get_values();
						let doctype = "Mentors";
						let docname = data.mentor;
						let appointment_id = data.appointment_id
						frappe.call({
							method: "lms.lms.utils.get_payment_options",
							args: {
								doctype: doctype,
								docname: docname,
								phone: new_address.phone,
								country: new_address.country,
								appointment_id: appointment_id
							},
							callback: (data) => {
								data.message.handler = (response) => {
									handle_success(
										response,
										doctype,
										docname,
										new_address,
										data.message.order_id,
										appointment_id
									);
								};
								let rzp1 = new Razorpay(data.message);
								rzp1.open();
							},
						});
					};


					this.billing.set_values({
						'billing_name': address.address_title,
						'appointment_date': frm.doc.appointment_date,
					});

					frm.set_value('billing_name', address.address_title);
					this.billing.set_value('address_line1', address.address_line1);
					this.billing.set_value('address_line2', address.address_line2 || ''); // Use empty string for null
					this.billing.set_value('city', address.city);
					this.billing.set_value('state', address.state || ''); // Use empty string for null
					this.billing.set_value('country', address.country);
					this.billing.set_value('pincode', address.pincode);
					this.billing.set_value('phone', address.phone);
					this.billing.show();
					$("#billing-form .form-section:last").removeClass("empty-section");
					$("#billing-form .frappe-control").removeClass("hide-control");
					$("#billing-form .form-column").addClass("p-0");

				}
			}
		});



	};



	const handle_success = (response, doctype, docname, address, order_id, appointment_id) => {
		frappe.call({
			method: "lms.lms.utils.verify_payment",
			args: {
				response: response,
				doctype: doctype,
				docname: docname,
				address: address,
				order_id: order_id,
				appointment_id: appointment_id
			},
			callback: (data) => {
				frappe.show_alert({
					message: __("Payment Successful"),
					indicator: "green",
				});
				// TODO - Redirect
				setTimeout(() => {
					window.location.pathname = data.message;

					// window.location.href = data.message;
				}, 1000);
			},
		});
	};

	const change_currency = () => {
		$("#gst-message").removeClass("hide");
		let country = this.billing.get_value("country");
		if (exception_country.includes(country)) {
			update_price(original_price_formatted);
			return;
		}
		frappe.call({
			method: "lms.lms.utils.change_currency",
			args: {
				country: country,
				amount: amount,
				currency: currency,
			},
			callback: (data) => {
				let current_price = $(".total-price").text();
				if (current_price != data.message) {
					update_price(data.message);
				}
				if (!data.message.includes("INR")) {
					$("#gst-message").addClass("hide");
				}
			},
		});
	};

	const update_price = (price) => {
		$(".total-price").text(price);
		frappe.show_alert({
			message: "Total Price has been updated.",
			indicator: "yellow",
		});
	};

	function showMenteeForm(appointment) {

		frappe.call({
			method: 'mentorly_custom_app.mentorly_custom_app.custom_api.get_mentee_data',
			args: {
			  user: frappe.session.user // or frappe.session.user if you want the current user
			},
			callback: function(r) {
			  if (!r.exc) {

				const user_email = frappe.session; // Assuming this contains the logged-in user's email

				// Create a new dialog
				const mentee_form = new frappe.ui.Dialog({
					title: 'Share Your Details with Mentors',
					fields: [
						{
							fieldtype: 'Section Break',
							label: 'Personal Details'
						},
						{
							label: 'First Name',
							fieldname: 'first_name',
							fieldtype: 'Data',
							reqd: 1,
							read_only: 1,
							default: r.message.first_name,
							// fetch_from: 'user.first_name'
						},
						{
							fieldtype: 'Column Break'
						},
						{
							label: 'Last Name',
							fieldname: 'last_name',
							fieldtype: 'Data',
							// fetch_from: 'user.last_name',
							default: r.message.last_name,
							reqd: 1,
						},
						{
							fieldtype: 'Section Break',
						},
						{
							label: 'City',
							fieldname: 'city',
							fieldtype: 'Data',
							default: r.message.city,
							reqd: 1,
						},
						{
							fieldtype: 'Column Break'
						},
						{
							label: 'State',
							fieldname: 'state',
							fieldtype: 'Data',
							default: r.message.state,
							reqd: 1,
						},
						{
							fieldtype: 'Column Break'
						},
						{
							label: 'Country',
							fieldname: 'country',
							fieldtype: 'Link',
							options: 'Country',
							default: "India",
							reqd: 1,
						},
						{
							fieldtype: 'Section Break'
						},
						{
							label: 'Linkedin Profile',
							fieldname: 'linkedin_profile',
							fieldtype: 'Data',
							default: r.message.linkedin_profile,
							reqd: 1,
						},
						{
							fieldtype: 'Section Break',
							label: 'Company Information'
						},
						{
							label: 'Brief About Your Company',
							fieldname: 'brief_about_your_company',
							fieldtype: 'Small Text',
							default: r.message.brief_about_your_company,
							reqd: 1,
						},
						{
							label: 'Agenda',
							fieldname: 'agenda',
							fieldtype: 'Small Text',
							reqd: 1,
						}
					],
					primary_action_label: __('Proceed'),
					primary_action(values) {
						const values1 = mentee_form.get_values();

						if (values1) {
							frappe.call({
								method: "mentorly_custom_app.mentorly_custom_app.custom_api.save_or_update_mentee",
								args: {
									first_name: values1.first_name,
									last_name: values1.last_name,
									country: values1.country,
									state: values1.state,
									city: values1.city,
									linkedin_profile: values1.linkedin_profile,
									brief_about_your_company: values1.brief_about_your_company
								},
								callback: function(r) {

									appointment.agenda = values1.brief_about_your_company;

									if (r.message) {
										// If the Mentee data is saved or updated successfully
										// frappe.msgprint(__('Mentee data saved successfully.'));
										// mentee_form.hide();
									} else {
										// Handle the case where there's an error or no message
										// frappe.msgprint(__('There was an issue saving the Mentee data.'));
									}
									mentee_form.hide();
									createAppointment(appointment)
								}
							});
						}
						mentee_form.hide();
					}
				});
	
				if (r.message) {
				  console.log('Dialog prefilled with mentee data.');
				} else {
				  console.log('No mentee data found for the user.');
				}
					// Show the dialog
					mentee_form.show();
			  }
			}
		  });

	}

	function show_availability(e) {
		let d = new frappe.ui.Dialog({
			title: __('Available slots'),
			fields: [
				{ fieldtype: 'Link', reqd: 1, fieldname: 'mentor', label: 'Mentor', hidden: 1 },
				{ fieldtype: 'Date', reqd: 1, fieldname: 'appointment_date', label: 'Date', min_date: new Date(frappe.datetime.get_today()) },
				{ fieldtype: 'Section Break' },
				{ fieldtype: 'HTML', fieldname: 'available_slots' },
			],
			primary_action_label: __('Book'),
			primary_action: async function () {
				frm.set_value('appointment_time', selected_slot);
				add_video_conferencing = add_video_conferencing && !d.$wrapper.find(".opt-out-check").is(":checked")
					&& !overlap_appointments

				frm.set_value('add_video_conferencing', add_video_conferencing);
				if (!frm.doc.duration) {
					frm.set_value('duration', duration);
				}
				let current_user = frappe.session.user;
				frm.set_value('mentor', d.get_value('mentor'));
				frm.set_value('user', current_user);

				frm.set_value('appointment_date', d.get_value('appointment_date'));
				frm.set_value('appointment_based_on_check_in', appointment_based_on_check_in)
				d.hide();
				frm.enable_save();
				await frm.save();
				let doc = frm.doc
				let appointmentData = {
					"appointment_date": doc.appointment_date,
					"appointment_time": doc.appointment_time,
					"scheduled_time": doc.appointment_time,
					"duration": doc.duration,
					"mentor": decodeURIComponent(doc.mentor),
					"user": doc.user,
				};

				showMenteeForm(appointmentData)
				//frappe.msgprint("hi")
				console.log(frappe.session)
				//createAppointment(appointmentData)
				// d.get_primary_btn().attr('disabled', true);
			}
		});

		d.set_values({
			'mentor': course,
			'appointment_date': frm.doc.appointment_date,
		});

		// disable dialog action initially
		d.get_primary_btn().attr('disabled', true);

		// Field Change Handler
		let fd = d.fields_dict;

		d.fields_dict['appointment_date'].df.onchange = () => {
			console.log("Appointment Loaded")
			show_slots(d, fd, course);
		};

		d.show();
	}

	function show_slots(d, fd, course) {
		if (d.get_value('appointment_date')) {
			fd.available_slots.html('');
			frappe.call({
				method: 'mentorly_custom_app.mentorly_custom_app.custom_api.get_availability_data',
				args: {
					date: d.get_value('appointment_date'),
					mentor: decodeURIComponent(course)
				},
				callback: (r) => {
					let data = r.message;
					if (data.slot_details.length > 0) {
						let $wrapper = d.fields_dict.available_slots.$wrapper;

						// make buttons for each slot
						let slot_html = get_slots(data.slot_details, data.fee_validity, d.get_value('appointment_date'));

						$wrapper
							.css('margin-bottom', 0)
							.addClass('text-center')
							.html(slot_html);

						// highlight button when clicked
						$wrapper.on('click', 'button', function () {
							let $btn = $(this);
							$wrapper.find('button').removeClass('btn-outline-primary');
							$btn.addClass('btn-outline-primary');
							selected_slot = $btn.attr('data-name');
							service_unit = $btn.attr('data-service-unit');
							appointment_based_on_check_in = $btn.attr('data-day-appointment');
							duration = $btn.attr('data-duration');
							add_video_conferencing = parseInt($btn.attr('data-tele-conf'));
							overlap_appointments = parseInt($btn.attr('data-overlap-appointments'));
							// show option to opt out of tele conferencing
							if ($btn.attr('data-tele-conf') == 1) {
								if (d.$wrapper.find(".opt-out-conf-div").length) {
									d.$wrapper.find(".opt-out-conf-div").show();
								} else {
									overlap_appointments ?
										d.footer.prepend(
											`<div class="opt-out-conf-div ellipsis text-muted" style="vertical-align:text-bottom;">
												<label>
													<span class="label-area">
													${__("Video Conferencing disabled for group consultations")}
													</span>
												</label>
											</div>`
										)
										:
										d.footer.prepend(
											`<div class="opt-out-conf-div ellipsis" style="vertical-align:text-bottom;">
											<label>
												<input type="checkbox" class="opt-out-check"/>
												<span class="label-area">
												${__("Do not add Video Conferencing")}
												</span>
											</label>
										</div>`
										);
								}
							} else {
								d.$wrapper.find(".opt-out-conf-div").hide();
							}

							// enable primary action 'Book'
							d.get_primary_btn().attr('disabled', null);
						});

					} else {
						//	fd.available_slots.html('Please select a valid date.'.bold())
						show_empty_state(d.get_value('practitioner'), d.get_value('appointment_date'));
					}
				},
				freeze: true,
				freeze_message: __('Fetching Schedule...')
			});
		} else {
			fd.available_slots.html(__('Please Select Date for Booking Mentorship Slot').bold());
		}
	}

	function get_slots(slot_details, fee_validity, appointment_date) {
		let slot_html = '';
		let appointment_count = 0;
		let disabled = false;
		let start_str, slot_start_time, slot_end_time, interval, count, count_class, tool_tip, available_slots;

		slot_details.forEach((slot_info) => {
			slot_html += `<div class="slot-info">`;
			// if (fee_validity && fee_validity != 'Disabled') {
			// 	slot_html += `
			// 		<span style="color:green">
			// 		${__('Patient has fee validity till')} <b>${moment(fee_validity.valid_till).format('DD-MM-YYYY')}</b>
			// 		</span><br>`;
			// } else if (fee_validity != 'Disabled') {
			// 	slot_html += `
			// 		<span style="color:red">
			// 		${__('Patient has no fee validity')}
			// 		</span><br>`;
			// }

			// slot_html += `
			// 	<span><b>
			// 	${__('Practitioner Schedule: ')} </b> ${slot_info.slot_name}
			// 		${slot_info.tele_conf && !slot_info.allow_overlap ? '<i class="fa fa-video-camera fa-1x" aria-hidden="true"></i>' : ''}
			// 	</span><br>
			// 	<span><b> ${__('Service Unit: ')} </b> ${slot_info.service_unit}</span>`;
			// 	if (slot_info.service_unit_capacity) {
			// 		slot_html += `<br><span> <b> ${__('Maximum Capacity:')} </b> ${slot_info.service_unit_capacity} </span>`;
			// 	}
			slot_html += `<br><span> <b> ${__('Available Timeslots')} </b></span>`;

			slot_html += '</div><br>';

			slot_html += slot_info.avail_slot.map(slot => {
				appointment_count = 0;
				disabled = false;
				count_class = tool_tip = '';
				start_str = slot.from_time;
				slot_start_time = moment(slot.from_time, 'HH:mm:ss');
				slot_end_time = moment(slot.to_time, 'HH:mm:ss');
				interval = (slot_end_time - slot_start_time) / 60000 | 0;

				// restrict past slots based on the current time.
				let now = moment();
				let booked_moment = ""
				if ((now.format("YYYY-MM-DD") == appointment_date) && (slot_start_time.isBefore(now) && !slot.maximum_appointments)) {
					disabled = true;
				} else {
					// iterate in all booked appointments, update the start time and duration
					slot_info.appointments.forEach((booked) => {
						booked_moment = moment(booked.appointment_time, 'HH:mm:ss');
						let end_time = booked_moment.clone().add(booked.duration, 'minutes');

						// to get apointment count for all day appointments
						if (slot.maximum_appointments) {
							if (booked.appointment_date == appointment_date) {
								appointment_count++;
							}
						}
						// Deal with 0 duration appointments
						if (booked_moment.isSame(slot_start_time) || booked_moment.isBetween(slot_start_time, slot_end_time)) {
							if (booked.duration == 0) {
								disabled = true;
								return false;
							}
						}

						// Check for overlaps considering appointment duration
						if (slot_info.allow_overlap != 1) {
							if (slot_start_time.isBefore(end_time) && slot_end_time.isAfter(booked_moment)) {
								// There is an overlap
								disabled = true;
								return false;
							}
						} else {
							if (slot_start_time.isBefore(end_time) && slot_end_time.isAfter(booked_moment)) {
								appointment_count++;
							}
							if (appointment_count >= slot_info.service_unit_capacity) {
								// There is an overlap
								disabled = true;
								return false;
							}
						}
					});
				}
				if (slot_info.allow_overlap == 1 && slot_info.service_unit_capacity > 1) {
					available_slots = slot_info.service_unit_capacity - appointment_count;
					count = `${(available_slots > 0 ? available_slots : __('Full'))}`;
					count_class = `${(available_slots > 0 ? 'badge-success' : 'badge-danger')}`;
					tool_tip = `${available_slots} ${__('slots available for booking')}`;
				}

				if (slot.maximum_appointments) {
					if (appointment_count >= slot.maximum_appointments) {
						disabled = true;
					}
					else {
						disabled = false;
					}
					available_slots = slot.maximum_appointments - appointment_count;
					count = `${(available_slots > 0 ? available_slots : __('Full'))}`;
					count_class = `${(available_slots > 0 ? 'badge-success' : 'badge-danger')}`;
					return `<button class="btn btn-secondary" data-name=${start_str}
								data-service-unit="${slot_info.service_unit || ''}"
								data-day-appointment=${1}
								data-duration=${slot.duration}
								${disabled ? 'disabled="disabled"' : ""}>${slot.from_time} -
								${slot.to_time} ${slot.maximum_appointments ?
							`<br><span class='badge ${count_class}'>${count} </span>` : ''}</button>`
				} else {

					return `
							<button class="btn btn-secondary" data-name=${start_str}
								data-duration=${interval}
								data-service-unit="${slot_info.service_unit || ''}"
								data-tele-conf="${slot_info.tele_conf || 0}"
								data-overlap-appointments="${slot_info.service_unit_capacity || 0}"
								style="margin: 0 10px 10px 0; width: auto;" ${disabled ? 'disabled="disabled"' : ""}
								data-toggle="tooltip" title="${tool_tip || ''}">
								${start_str.substring(0, start_str.length - 3)}
								${slot_info.service_unit_capacity ? `<br><span class='badge ${count_class}'> ${count} </span>` : ''}
							</button>`;

				}
			}).join("");

			if (slot_info.service_unit_capacity) {
				slot_html += `<br/><small>${__('Each slot indicates the capacity currently available for booking')}</small>`;
			}
			slot_html += `<br/><br/>`;

		});

		return slot_html;
	}
};

const notify_user = (e) => {
	e.preventDefault();
	var course = decodeURIComponent($("#outline-heading").attr("data-course"));
	if (frappe.session.user == "Guest") {
		window.location.href = `/login?redirect-to=/courses/${course}`;
		return;
	}

	frappe.call({
		method: "lms.lms.doctype.lms_course_interest.lms_course_interest.capture_interest",
		args: {
			course: course,
		},
		callback: (data) => {
			$(".no-preview-modal").modal("hide");
			frappe.show_alert(
				{
					message: __(
						"You have opted to be notified for this course. You will receive an email when the course becomes available."
					),
					indicator: "green",
				},
				3
			);
			setTimeout(() => {
				window.location.reload();
			}, 3000);
		},
	});
};

const generate_graph = (chart_name, element, type = "line") => {
	let date = frappe.datetime;

	frappe.call({
		method: "lms.lms.utils.get_chart_data",
		args: {
			chart_name: chart_name,
			timespan: "Select Date Range",
			timegrain: "Daily",
			from_date: date.add_days(date.get_today(), -30),
			to_date: date.add_days(date.get_today(), +1),
		},
		callback: (data) => {
			render_chart(data.message, chart_name, element, type);
		},
	});
};

const render_chart = (data, chart_name, element, type) => {
	const chart = new frappe.Chart(element, {
		title: chart_name,
		data: data,
		type: type,
		height: 250,
		colors: ["#4563f1"],
		axisOptions: {
			xIsSeries: 1,
		},
		lineOptions: {
			regionFill: 1,
		},
	});
};

const generate_course_completion_graph = () => {
	frappe.call({
		method: "lms.lms.utils.get_course_completion_data",
		callback: (data) => {
			render_chart(
				data.message,
				"Course Completion",
				"#course-completion",
				"pie"
			);
		},
	});
};

const change_hash = (e) => {
	window.location.hash = $(e.currentTarget).attr("href");
};

const open_tab = () => {
	$(`a[href="${window.location.hash}"]`).click();
};

const expand_the_first_chapter = () => {
	let elements = $(".course-home-outline .collapse");
	elements.each((i, element) => {
		if (i < 1) {
			show_section(element);
			return false;
		}
	});
};

const expand_the_active_chapter = () => {
	let selector = $(".course-home-headings.title");

	if (selector.length && $(".course-details-page").length) {
		expand_for_course_details(selector);
	} else if ($(".active-lesson").length) {
		/* For course home page */
		selector = $(".active-lesson");
		show_section(selector.parent().parent());
	} else {
		/* If no active chapter then exapand the first chapter */
		expand_the_first_chapter();
	}
};

const expand_for_course_details = (selector) => {
	$(".lesson-info").removeClass("active-lesson");
	$(".lesson-info").each((i, elem) => {
		if ($(elem).data("lesson") == selector.data("lesson")) {
			$(elem).addClass("active-lesson");
			show_section($(elem).parent().parent());
		}
	});
};

const show_section = (element) => {
	$(element).addClass("show");
	$(element)
		.siblings(".chapter-title")
		.children(".chapter-icon")
		.css("transform", "rotate(90deg)");
	$(element).siblings(".chapter-title").attr("aria-expanded", true);
};

const rotate_chapter_icon = (e) => {
	let icon = $(e.currentTarget).children(".chapter-icon");
	if (icon.css("transform") == "none") {
		icon.css("transform", "rotate(90deg)");
	} else {
		icon.css("transform", "none");
	}
};

const show_no_preview_dialog = (e) => {
	$("#no-preview-modal").modal("show");
};

const open_batch_dialog = () => {
	this.batch_dialog = new frappe.ui.Dialog({
		title: __("New Batch"),
		fields: [
			{
				fieldtype: "Data",
				label: __("Title"),
				fieldname: "title",
				reqd: 1,
				default: batch_info && batch_info.title,
			},
			{
				fieldtype: "Check",
				label: __("Published"),
				fieldname: "published",
				default: batch_info && batch_info.published,
			},
			{
				fieldtype: "Column Break",
			},
			{
				fieldtype: "Int",
				label: __("Seat Count"),
				fieldname: "seat_count",
				default: batch_info && batch_info.seat_count,
			},
			{
				fieldtype: "Section Break",
			},
			{
				fieldtype: "Date",
				label: __("Start Date"),
				fieldname: "start_date",
				reqd: 1,
				default: batch_info && batch_info.start_date,
			},
			{
				fieldtype: "Date",
				label: __("End Date"),
				fieldname: "end_date",
				reqd: 1,
				default: batch_info && batch_info.end_date,
			},
			{
				fieldtype: "Select",
				label: __("Medium"),
				fieldname: "medium",
				options: ["Online", "Offline"],
				default: (batch_info && batch_info.medium) || "Online",
			},
			{
				fieldtype: "Column Break",
			},
			{
				fieldtype: "Time",
				label: __("Start Time"),
				fieldname: "start_time",
				default: batch_info && batch_info.start_time,
			},
			{
				fieldtype: "Time",
				label: __("End Time"),
				fieldname: "end_time",
				default: batch_info && batch_info.end_time,
			},
			{
				fieldtype: "Link",
				label: __("Category"),
				fieldname: "category",
				options: "LMS Category",
				only_select: 1,
				default: batch_info && batch_info.category,
			},
			{
				fieldtype: "Section Break",
			},
			{
				fieldtype: "Small Text",
				label: __("Description"),
				fieldname: "description",
				default: batch_info && batch_info.description,
				reqd: 1,
			},
			{
				fieldtype: "Text Editor",
				label: __("Batch Details"),
				fieldname: "batch_details",
				default: batch_info && batch_info.batch_details,
				reqd: 1,
			},
			{
				fieldtype: "HTML Editor",
				label: __("Batch Details Raw"),
				fieldname: "batch_details_raw",
				default: batch_info && batch_info.batch_details_raw,
			},
			{
				fieldtype: "Attach Image",
				label: __("Meta Image"),
				fieldname: "meta_image",
				default: batch_info && batch_info.meta_image,
			},
			{
				fieldtype: "Section Break",
				label: __("Pricing"),
				fieldname: "pricing",
			},
			{
				fieldtype: "Check",
				label: __("Paid Batch"),
				fieldname: "paid_batch",
				default: batch_info && batch_info.paid_batch,
			},
			{
				fieldtype: "Currency",
				label: __("Amount"),
				fieldname: "amount",
				default: batch_info && batch_info.amount,
				mandatory_depends_on: "paid_batch",
				depends_on: "paid_batch",
			},
			{
				fieldtype: "Link",
				label: __("Currency"),
				fieldname: "currency",
				options: "Currency",
				default: batch_info && batch_info.currency,
				mandatory_depends_on: "paid_batch",
				depends_on: "paid_batch",
				only_select: 1,
			},
		],
		primary_action_label: __("Save"),
		primary_action: (values) => {
			save_batch(values);
		},
	});
	this.batch_dialog.show();
};

const save_batch = (values) => {
	let args = {};
	if (batch_info) {
		args = Object.assign(batch_info, values);
	} else {
		args = values;
	}
	frappe.call({
		method: "lms.lms.doctype.lms_batch.lms_batch.create_batch",
		args: args,
		callback: (r) => {
			if (r.message) {
				frappe.show_alert({
					message: batch_info
						? __("Batch Updated")
						: __("Batch Created"),
					indicator: "green",
				});
				this.batch_dialog.hide();
				window.location.href = `/batches/details/${r.message.name}`;
			}
		},
	});
};

const filter_courses = (e) => {
	const course_lists = $(".course-cards-parent");
	const filter = $(e.currentTarget).val();
	course_lists.each((i, list) => {
		const course_cards = $(list).children(".course-card");
		course_cards.sort((a, b) => {
			var value1 = $(a).data(filter);
			var value2 = $(b).data(filter);
			return value1 > value2 ? -1 : value1 < value2 ? 1 : 0;
		});
		$(list).append(course_cards);
	});
};
